'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import space from '@haaretz/l-space.macro';
import useArticleId from '@haaretz/s-atoms/articleId';
import usePlatform from '@haaretz/s-atoms/platform';
import useUser from '@haaretz/s-atoms/user';
import ClickArea from '@haaretz/s-click-area';
import FeatureBlockModal from '@haaretz/s-feature-block-modal';
import Icon from '@haaretz/s-icon';
import { useUpdateReadingListMutation } from '@haaretz/s-mutations/UpdateReadingList';
import { useCheckArticlePersonalQuery } from '@haaretz/s-queries/CheckArticle';
import useBi from '@haaretz/s-use-bi';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import dynamic from 'next/dynamic';
import * as React from 'react';
import s9 from 'style9';

import ActionBtnPopover from '../ActionBtnPopover/ActionBtnPopover';

import type { ActionBtnWithOffsetProps } from '../../types';
import type { HtzUserType } from '@haaretz/s-types';

const Popover = dynamic(() => import('@haaretz/s-popover').then(mod => mod.default));

// `c` is short for `classNames`
const c = s9.create({
  alerts: {
    display: 'flex',
    columnGap: space(4),
  },
  title: {
    color: color('secondary1000'),
  },
  subTitle: {
    maxWidth: space(60),
  },
  popoverBorder: {
    padding: 0,
    borderWidth: 0,
  },
  cappedPopover: {
    minWidth: space(80),
  },
});

const SHOW_CAPPED = 'SHOW_CAPPED';

function getShowCappedStatus() {
  let showCapped = true;
  try {
    showCapped = localStorage.getItem(SHOW_CAPPED) !== 'true';
  } catch (e) {
    console.error(e);
  }

  return showCapped;
}

function onChange(evt: React.ChangeEvent<HTMLInputElement>) {
  try {
    localStorage.setItem(SHOW_CAPPED, evt.target.checked.toString());
  } catch (e) {
    console.error(e);
  }
}

type PopoverType = 'success' | 'capped';

function BookmarkBtn(props: ActionBtnWithOffsetProps) {
  const {
    clickAreaStyleExtend,
    iconStyleExtend = [],
    offsetValue,
    contentId,
    textStyleExtend = [],
    showText = true,
    id: domId,
  } = props;
  const articleId = useArticleId();
  const user = useUser('serverValue');
  const [userType, setUserType] = React.useState<HtzUserType | null>(null);

  const [popoverType, setPopoverType] = React.useState<PopoverType | null>(null);
  const biAction = useBi();
  const isLoggedInUser = !!userType && userType !== 'anonymous';

  const id = contentId || articleId || '';

  // HACK: without this unused dummy variable, tests will fail
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, isFetching: _ } = useCheckArticlePersonalQuery(
    {
      input: { articleId: id },
    },
    {
      enabled: typeof document !== 'undefined' && isLoggedInUser && !!id,
    }
  );

  const [isSaved, setIsSaved] = React.useState(false);
  const [isReadingListFull, setIsReadingListFull] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const refersToRef = React.useRef(null);
  const platform = usePlatform();

  React.useEffect(() => {
    setUserType(user.userType);
  }, [user.userType]);

  const updateReadingListMutation = useUpdateReadingListMutation();

  React.useEffect(() => {
    if (data?.checkArticle) {
      setIsSaved(!!data?.checkArticle?.exist);
      setIsReadingListFull(!!data?.checkArticle?.full);
      setIsChecked(true);
    } else {
      setIsChecked(false);
      setIsSaved(false);
      setIsReadingListFull(false);
    }
  }, [data?.checkArticle]);

  React.useEffect(() => {
    // occurs onClick only when success popover is open
    if (isPopoverOpen && popoverType === 'success') {
      biAction({
        feature: 'Article Page - Reading List',
        feature_type: 'Content',
        campaign_name: 'Reading List Button',
        action_id: userType === 'paying' ? 138 : 88,
      });
    }
  }, [biAction, isPopoverOpen, popoverType, platform, userType]);

  if (typeof window === 'undefined' || !userType) {
    // Bookmark btn should not render on the server, so that the fallback is used
    return <BookmarkFallback {...props} />;
  }

  function onToggle() {
    if (popoverType === 'success') {
      if (isSaved) {
        setIsPopoverOpen(false);
      }
    } else {
      setIsPopoverOpen(prev => !prev);
    }
  }

  function onClose() {
    setIsPopoverOpen(false);
  }

  function onModalClose() {
    setIsModalOpen(false);
  }

  function updateReadingList() {
    updateReadingListMutation.mutate(
      {
        input: { action: isSaved ? 'removeArticle' : 'addArticle', articleId: id },
      },
      {
        onSuccess: ({ updateReadingList: { status } }) => {
          if (status === 'ok') {
            setIsSaved(prev => {
              setIsPopoverOpen(!prev);
              return !prev;
            });
            setPopoverType('success');
          }
        },
      }
    );
  }

  function onClick() {
    if (isLoggedInUser) {
      const shouldShowCapped = getShowCappedStatus();

      if (isReadingListFull && shouldShowCapped && !isSaved) {
        setPopoverType('capped');
        setIsPopoverOpen(true);
      } else {
        updateReadingList();
      }
    }

    const isPayingUser = user.userType === 'paying';

    if (!isLoggedInUser || !isPayingUser) {
      setIsModalOpen(prev => !prev);
    }
  }

  function createAlert(alertType: 'week' | 'day') {
    updateReadingListMutation.mutate(
      {
        input: { action: 'createAlert', articleId: id, alertType },
      },
      {
        onSettled: () => {
          onToggle();
        },
      }
    );
  }

  function modalOnToggle(innerIsOpen: boolean) {
    if (innerIsOpen) {
      biAction({
        feature: 'Article Page - Reading List',
        feature_type: 'Content',
        campaign_name: 'Reading List Button',
        action_id: user.userType === 'paying' ? 138 : 88,
      });
    }
    setIsModalOpen(innerIsOpen);
  }

  const popoverByType = {
    success: (
      <ActionBtnPopover
        title={fork({ default: 'הכתבה נשמרה' })}
        subTitle={fork({ default: 'האם תרצו לקבל תזכורת למייל?' })}
        onClose={onClose}
        btn1={{ text: fork({ default: 'מחר' }), onClick: () => createAlert('day') }}
        btn2={{ text: fork({ default: 'בשבוע הבא' }), onClick: () => createAlert('week') }}
        enableFomoTooltip={true}
        biData={{
          feature: 'Article Page - Reading List',
          feature_type: 'Content',
          campaign_name: 'Reading List',
          campaign_details: 'Reading List',
        }}
        fomoTooltipProps={{
          text: fork({
            default: 'רוצה להמשיך ליהנות מהאפשרות לשמור כתבות?',
            hdc: 'Want to continue enjoying the option to save articles?',
          }),
          buttonText: fork({
            default: 'לחידוש המינוי',
            hdc: 'Renew subscription',
          }),
        }}
      />
    ),
    capped: (
      <ActionBtnPopover
        title={fork({ default: `רשימת הקריאה מוגבלת ל-${data?.checkArticle?.total} כתבות.` })}
        titleStyleExtend={[c.title]}
        subTitle={fork({ default: 'עם שמירת כתבה חדשה תימחק הכתבה הישנה ביותר.' })}
        subTitleStyleExtend={[c.subTitle]}
        styleExtend={[c.cappedPopover]}
        onClose={onClose}
        btn1={{
          text: fork({ default: 'הבנתי' }),
          onClick: updateReadingList,
          buttonComponent: { variant: 'brand' },
        }}
        btn2={{
          text: fork({ default: 'בטל' }),
          onClick: onClose,
          buttonComponent: { priority: 'tertiary' },
        }}
        checkbox={fork({ default: 'אל תציג לי הודעה זו שוב' })}
        checkboxOnChange={onChange}
        biData={{
          feature: 'Reading List Alert',
          feature_type: 'Content',
          campaign_name: 'Reading List',
          campaign_details: 'Reading List',
        }}
      />
    ),
  };

  const isDisabled = updateReadingListMutation.isLoading || (isLoggedInUser && !isChecked);

  return (
    <>
      <ClickArea
        styleExtend={clickAreaStyleExtend}
        rippleSize="small"
        onClick={onClick}
        data-testid="bookmark-btn"
        disabled={isDisabled}
        aria-disabled={isDisabled || undefined}
        title={fork({ default: 'שמירת כתבה', hdc: 'Save article' })}
        id={domId}
      >
        <Icon
          icon={isSaved ? 'bookmark-off' : 'bookmark'}
          ref={refersToRef}
          variant="neutral"
          styleExtend={[...iconStyleExtend]}
        />
        {showText ? (
          <span className={s9(...textStyleExtend)}>
            {fork({ default: isSaved ? 'הסרה' : 'שמירה', hdc: isSaved ? 'Remove' : 'Save' })}
          </span>
        ) : (
          <VisuallyHidden>{fork({ default: 'שמירת כתבה', hdc: 'Save article' })}</VisuallyHidden>
        )}
      </ClickArea>
      {popoverType && (
        <Popover
          refersToRef={refersToRef}
          onToggle={onToggle}
          isOpen={isPopoverOpen}
          defaultIsOpen={false}
          placement="bottom"
          offsetValue={offsetValue}
          keepInView
          styleExtend={[c.popoverBorder]}
        >
          {popoverByType[popoverType]}
        </Popover>
      )}
      {isModalOpen && (
        <FeatureBlockModal
          type="reading"
          isOpen={isModalOpen}
          onToggle={modalOnToggle}
          onClose={onModalClose}
        />
      )}
    </>
  );
}

function BookmarkFallback({
  clickAreaStyleExtend,
  iconStyleExtend = [],
  textStyleExtend = [],
  showText = true,
}: ActionBtnWithOffsetProps) {
  return (
    <ClickArea
      styleExtend={clickAreaStyleExtend}
      rippleSize="small"
      data-testid="bookmark-fallback-btn"
      disabled
      aria-disabled
      title={fork({ default: 'שמירת כתבה', hdc: 'Save article' })}
    >
      <Icon icon={'bookmark'} variant="neutral" styleExtend={[...iconStyleExtend]} />
      {showText ? (
        <span className={s9(...textStyleExtend)}>{fork({ default: 'שמירה', hdc: 'Save' })}</span>
      ) : (
        <VisuallyHidden>
          {fork({ default: 'שמירת כתבה (בטעינה)', hdc: 'Save article (loading)' })}
        </VisuallyHidden>
      )}
    </ClickArea>
  );
}

export default function BookmarkBtnWithSuspense(props: ActionBtnWithOffsetProps) {
  return (
    <React.Suspense fallback={<BookmarkFallback {...props} />}>
      <BookmarkBtn {...props} />
    </React.Suspense>
  );
}
