'use client';

import useImpressionObserver from '@haaretz/s-use-impression-observer';
import * as React from 'react';
import s9 from 'style9';

import FomoTooltipButton from './FomoTooltipButton';
import useDisplayFomoTooltip from './useDisplayFomoTooltip';

import type { BiDataOverrides } from '@haaretz/s-data-structure-types';
import type { StyleExtend } from '@haaretz/s-types';

export interface FomoTooltipProps {
  biData: BiDataOverrides;
  text: string;
  buttonText: string;
  wrapperStyleExtend?: StyleExtend;
  textStyleExtend?: StyleExtend;
  buttonStyleExtend?: StyleExtend;
}

export function FomoTooltip({
  text,
  textStyleExtend = [],

  buttonText,
  buttonStyleExtend = [],

  wrapperStyleExtend = [],

  biData,
}: FomoTooltipProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const { feature, feature_type = 'Marketing', campaign_name = 'fomo', campaign_details } = biData;

  useImpressionObserver({
    biData: {
      feature,
      feature_type,
      campaign_name,
      campaign_details,
    },
    elementRef: ref,
  });

  return (
    <div className={s9(...wrapperStyleExtend)} ref={ref}>
      <span className={s9(...textStyleExtend)}>{text}</span>
      <FomoTooltipButton biData={biData} text={buttonText} styleExtend={buttonStyleExtend} />
    </div>
  );
}

export default function FomoTooltipWrapper(props: FomoTooltipProps) {
  const shouldDisplay = useDisplayFomoTooltip();

  return shouldDisplay ? <FomoTooltip {...props} /> : null;
}
