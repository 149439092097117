'use client';

/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import Button from '@haaretz/s-button';
import Checkbox from '@haaretz/s-checkbox';
import ClickArea from '@haaretz/s-click-area';
// TODO: Convert this to a dynamic import after the Next.js bug is fixed
//       (https://github.com/vercel/next.js/issues/63918)
import FomoTooltip from '@haaretz/s-fomo-tooltip';
import Icon from '@haaretz/s-icon';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import * as React from 'react';
import s9 from 'style9';

import { PopoverType } from '../GiftBtn/GiftBtn';

import type { ButtonProps } from '@haaretz/s-button';
import type { BiDataOverrides } from '@haaretz/s-data-structure-types';
import type { FomoTooltipProps } from '@haaretz/s-fomo-tooltip';
import type { IconProps } from '@haaretz/s-icon';
import type { StyleExtend } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  commentsText: {
    ...typesetter(-2),

    ...mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-1) } }),
  },
  popover: {
    display: 'flex',
    flexDirection: 'column',
  },
  popoverInner: {
    paddingTop: space(2),
    paddingBottom: space(3),
    paddingLeft: space(3),
    paddingRight: space(3),
  },
  closeBtnWrapper: {
    transform: `translate(${space(-2)}, ${space(-2)})`,
    position: 'absolute',
    zIndex: 10,
    alignSelf: 'flex-end',
    fontSize: space(4),
    top: space(4),
    insetInlineEnd: space(2),
  },
  titleWrapper: {
    paddingTop: space(3),
    ...typesetter(0),

    ...merge(
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-1) } }),
      mq({ from: 'xxl', value: { ...typesetter(-2) } })
    ),
  },
  title: {
    fontWeight: 700,
  },
  subTitle: {
    fontWeight: 400,
  },
  btn: {
    color: color('primary1000'),
    display: 'flex',
    alignItems: 'center',
    columnGap: space(1),
    backgroundColor: 'transparent',

    ':hover': {
      color: color('primary800'),
    },
  },
  btnText: {
    fontWeight: 700,

    ...typesetter(-2),

    ...merge(
      mq({ from: 's', until: 'xl', value: { ...typesetter(-1) } }),
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-2) } }),
      mq({ from: 'xxl', value: { ...typesetter(-3) } })
    ),
  },
  buttonsWrapper: {
    display: 'flex',
    columnGap: space(4),
    marginTop: space(2),
    justifyContent: 'flex-end',
  },
  successColor: {
    color: color('quaternary700'),
  },
  closeIcon: {
    lineHeight: 0,
  },
  checkbox: {
    marginTop: space(2),
    marginBottom: space(4),
  },
  label: {
    ...typesetter(-1),

    ...merge(
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-2) } }),
      mq({ from: 'xxl', value: { ...typesetter(-3) } })
    ),
  },
  popoverBtn: {
    whiteSpace: 'nowrap',
  },

  fomoWrapper: {
    backgroundColor: color('primary100'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: space(3),
    fontWeight: 700,
    paddingTop: space(3),
    paddingBottom: space(3),
    paddingLeft: space(4),
    paddingRight: space(4),
    ...merge(
      mq({
        until: 's',
        value: {
          paddingTop: space(3),
          marginTop: space(3),
        },
      }),
      mq({
        from: 's',
        value: {
          paddingTop: space(3),
          marginTop: space(2),
        },
      })
    ),
    ...merge(
      mq({ until: 's', value: { ...typesetter(-2) } }),
      mq({ from: 's', until: 'xl', value: { ...typesetter(0) } }),
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-1) } }),
      mq({ from: 'xxl', value: { ...typesetter(-2) } })
    ),
  },
  fomoText: {
    whiteSpace: 'nowrap',
  },
  fomoButton: {
    border: 'none',
    ...merge(
      mq({
        until: 's',
        value: { ...typesetter(-2), paddingTop: space(0.5), paddingBottom: space(0.5) },
      }),
      mq({ from: 's', until: 'xl', value: { ...typesetter(0) } }),
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-1) } }),
      mq({ from: 'xxl', value: { ...typesetter(-2) } })
    ),
  },
});

type PopoverBtnProps = {
  text: string;
  icon?: IconProps['icon'];
  iconVariant?: IconProps['variant'];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  buttonComponent?: {
    variant?: ButtonProps['variant'];
    priority?: ButtonProps['priority'];
  } & ({ href: ButtonProps<'a'>['href'] } | { href?: never });
};

type WithFomoTooltip =
  | { enableFomoTooltip: true; fomoTooltipProps: Pick<FomoTooltipProps, 'text' | 'buttonText'> }
  | { enableFomoTooltip?: false; fomoTooltipProps?: undefined };

type ActionBtnPopoverProps = {
  title?: string;
  subTitle?: string;
  btn1?: PopoverBtnProps;
  btn2?: PopoverBtnProps;
  onClose?: () => void;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Button styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
  titleStyleExtend?: StyleExtend;
  subTitleStyleExtend?: StyleExtend;
  /** The Children to be rendered inside `<ActionBtnPopover>` */
  children?: React.ReactNode;
  checkbox?: string;
  checkboxOnChange?: React.ChangeEventHandler<HTMLInputElement>;
  /**
   * Whether to disable the impression observer or not.
   *
   * @defaultValue true
   */
  disabledImpression?: boolean;
  nonPaying?: boolean;
  popoverType?: PopoverType | null;
  biData?: BiDataOverrides;
} & WithFomoTooltip;

const PopoverBtn = ({ text, icon, onClick, iconVariant, buttonComponent }: PopoverBtnProps) => {
  if (buttonComponent) {
    return (
      <Button
        {...buttonComponent}
        onClick={onClick}
        as={buttonComponent.href ? 'a' : 'button'}
        styleExtend={[c.popoverBtn]}
      >
        {text}
      </Button>
    );
  }

  return (
    <button className={s9(c.btn, c.popoverBtn)} onClick={onClick}>
      {icon && <Icon icon={icon} variant={iconVariant} />}
      <span className={s9(c.btnText, iconVariant === 'success' && c.successColor)}>{text}</span>
    </button>
  );
};

export default function ActionBtnPopover({
  title,
  subTitle,
  btn1,
  btn2,
  styleExtend = [],
  onClose,
  children = null,
  titleStyleExtend = [],
  subTitleStyleExtend = [],
  checkbox,
  checkboxOnChange,
  popoverType,
  enableFomoTooltip = false,
  fomoTooltipProps,
  biData,
}: ActionBtnPopoverProps) {
  const popoverRef = React.useRef(null);

  const { feature, feature_type, campaign_name, campaign_details, action_id } = biData || {};

  useImpressionObserver({
    biData: {
      feature,
      feature_type,
      campaign_name,
      campaign_details,
    },
    elementRef: popoverRef,
    disabled: popoverType === 'error' || !biData,
  });

  return (
    <div className={s9(c.popover, ...styleExtend)} ref={popoverRef}>
      <div className={s9(c.popoverInner)}>
        <div className={s9(c.closeBtnWrapper)}>
          <ClickArea rippleSize="small" onClick={onClose}>
            <Icon icon="close" />
          </ClickArea>
        </div>
        {children}
        {title && (
          <div className={s9(c.titleWrapper)}>
            <h3 className={s9(c.title, ...titleStyleExtend)}>{title}</h3>
            {subTitle && <h4 className={s9(c.subTitle, ...subTitleStyleExtend)}>{subTitle}</h4>}
          </div>
        )}
        {checkbox && (
          <Checkbox
            styleExtend={[c.checkbox]}
            labelStyleExtend={[c.label]}
            onChange={checkboxOnChange}
          >
            {checkbox}
          </Checkbox>
        )}
        {btn1 && (
          <div className={s9(c.buttonsWrapper)}>
            <PopoverBtn {...btn1} onClick={btn1.onClick} />
            {btn2 && <PopoverBtn {...btn2} onClick={btn2.onClick} />}
          </div>
        )}
      </div>
      {enableFomoTooltip && fomoTooltipProps && (
        <FomoTooltip
          {...(fomoTooltipProps || {})}
          wrapperStyleExtend={[c.fomoWrapper]}
          textStyleExtend={[c.fomoText]}
          buttonStyleExtend={[c.fomoButton]}
          biData={{
            feature,
            feature_type: 'Marketing',
            campaign_name: 'fomo',
            campaign_details,
            action_id,
          }}
        />
      )}
    </div>
  );
}
